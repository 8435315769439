@import '../../../app.scss';

#glassTableNums {
  width: 3%;
}

.glassDetsImg {
  max-width: 45%;
}

#glassImage {
  width: 8%;
}

#glassCode {
  width: 20%;
}

#glassImage-container {
  height: 2em;
  width: 100%;
}

#glassDelete {
  width: 3%;
}
