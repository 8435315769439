@import '../../../app.scss';

#donationId {
  width: 5%;
}

#donationLogo {
  width: 25%;
}

#donationLogo-container {
  height: 5em;
  width: 100%;
}
