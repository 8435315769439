@import '../../../app.scss';

#movements-search-section {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#eidColumn {
  width: 5%;
}

#periodColumn {
  width: 5%;
}

#pgIdColumn {
  width: 5%;
}

#dtColumn {
  width: 10%;
}

#statusColumn {
  width: 7%;
}

#errorColumn {
  width: 12%;
}

#eventColumn {
  width: 12%;
}

#glassCodeColumn {
  width: 8%;
}

#refundTypeColumn {
  width: 8%;
}

#entityDonationColumn {
  width: 18%;
}

#cycleColumn {
  width: 5%;
}
