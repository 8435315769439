@import '../../../app.scss';

.profile-index-container {
  height: 96vh;
  margin: 1% 2% 1% 5%;
  padding: 1%;
}

@media screen and (max-width: 1600px) {
  .profile-index-container {
    height: 100vh;
    margin: 0 2% 0 5%;
  }
}

.profile-page-form {
  height: 100%;
}

.profile-page-title-section {
  height: 5%;
  padding-left: 22%;
}

.profile-page-title {
  border-bottom: 1px solid #e7ebee;
}

/* .profile-page-content-section {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
} */

/* .profile-page-left {
  display: inline-block;
  width: 55%;
  height: 100%;
} */

.profile-page-left-info-section {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background-color: white;
  border-radius: 4px;
  padding: 2%;
}

.profile-page-left-password-section {
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-top: 2%;
  padding: 2%;
  background-color: white;
  border-radius: 4px;
  transition: ease-out 0.2s;
}

.password-input {
  margin-top: 2%;
}

.change-password-btn {
  width: 55%;
  background-color: $zarph-color;
  border: solid 1px $zarph-color;
}

.change-password-btn:hover {
  background-color: $zarph-dark-color;
  border: solid 1px $zarph-dark-color;
}

.change-password-btn:focus {
  box-shadow: none !important;
}

.password-section-close {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.password-section-close-btn {
  height: 95%;
  background-color: transparent !important;
  border: none !important;
  color: $zarph-color !important;
  box-shadow: none !important;
}

.profile-page-left-pin-section {
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-top: 2%;
  padding: 2%;
  background-color: white;
  border-radius: 4px;
}

.profile-page-left-permissions-section {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  height: auto;
  margin-top: 2%;
  padding: 2%;
  background-color: white;
  border-radius: 4px;
}

.profile-page-right {
  display: inline-block;
  width: 43%;
  height: 100%;
}

.profile-page-right-delegation-section {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 3%;
  background-color: white;
  border-radius: 4px;
}

table th:first-child {
  border-radius: 4px 0 0 0;
  width: 50%;
}

table th:last-child {
  border-radius: 0 4px 0 0;
  width: 50%;
}

.delegation-table-header {
  background-color: $zarph-color;
  color: $zarph-white;
}

.profile-page-button-section {
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.left-button {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.right-button {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.password-confirmation-modal-footer {
  justify-content: center !important;
}

@media only screen and (max-width: 600px) {
  .change-password-btn {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .change-password-btn {
    width: 25vw;
  }
}

@media only screen and (min-width: 768px) {
  .change-password-btn {
    width: 20vw;
  }
}

@media only screen and (min-width: 992px) {
  .change-password-btn {
    width: 15vw;
  }

  .password-section-close-btn {
    height: 33%;
  }
}

@media only screen and (min-width: 1200px) {
  .change-password-btn {
    width: 13vw;
  }
}

@media only screen and (min-width: 1400px) {
  .change-password-btn {
    width: 11vw;
  }
}
