@import '../../../app.scss';

.search-container {
  padding: 1% 0;
}

#search-box {
  width: 15%;
}

.iconCol {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.iconCol svg {
  margin-right: 2%;
}

#buttonCol {
  padding-top: 1.7%;
}

#eventName {
  width: 25%;
}

#eventAddress {
  width: 25%;
}

#eventZip {
  width: 20%;
}

#eventBeginDate {
  width: 15%;
}

.eventAd-container {
  height: 15em;
  width: 75%;
}

.eventCupsImage {
  height: 100%;
  width: 30%;
  object-fit: scale-down;
}

.eventCup-container {
  height: 5em;
  width: 100%;
}

.eventDonImage {
  height: 100%;
  width: 30%;
  object-fit: cover;
}

#eventDon-td {
  width: 30%;
}

.event-details-table {
  padding: 0 1.5% 1% 1.5%;
  background-color: #f8f9fa;
  color: $zarph-color;
  overflow-y: scroll;
  height: 55vh;
}

#eventAd-table::-webkit-scrollbar {
  display: none;
}
