@import '../../../app.scss';

#idColumn {
  width: 50%;
}

#distributionEventColumn {
  width: 50%;
}

.distribution-details-table {
  padding: 0 1.5% 1% 1.5%;
  background-color: #f8f9fa;
  color: #004960;
  overflow-y: scroll;
}

.distribution-details-table::-webkit-scrollbar {
  display: none;
}

.tab-container {
  padding: 0 1%;
}

.distribution-sections-table-head {
  background-color: #004960;
  color: #f8f9fa;
}

.distribution-sections-table-head-row {
  font-weight: normal !important;
  padding: 1%;
}

.distribution-sections-table-body-row {
  vertical-align: middle;
}

#emailColumn {
  width: 90%;
}

.distribution-sections-table-delet-btn {
  background-color: transparent !important;
  border: transparent !important;
  color: #004960 !important;
}

.user-picker-row {
  display: flex;
  flex-direction: row;
}

#user-picker-label-entity {
  width: 10%;
}

#user-picker-select-entity {
  width: 15%;
}

#user-picker-label-name {
  width: 8%;
}

#user-picker-input-name {
  width: 25%;
}

#user-picker-label-id {
  width: 4%;
}

#user-picker-input-id {
  width: 15%;
}

#user-picker-btn {
  width: 10%;
}

#uidColumn {
  width: 10%;
}

#userNameColumn {
  width: 80%;
}

#deleteColumn {
  width: 10%;
}
