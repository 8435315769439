.login {
  background: rgb(0, 109, 143);
  background: linear-gradient(169deg, rgba(0, 109, 143, 1) 0%, rgba(0, 73, 96, 1) 100%);

  .login-button {
    background-color: transparent;
    outline: transparent;
    border: 1px solid white;

    &:hover {
      background-color: #004960;
      border: 1px solid white;
    }

    &:focus {
      outline: none;
      outline-style: none;
      background-color: #004960;
      border: 1px solid white;
    }
  }
}
