@import "../../../app.scss";

.modal-header {
    background-color: $zarph-color;
    color: #f8f9fa;
}

.modal-header button {
    color: #f8f9fa;
}

.modal-header button:hover {
    color: #E7EBEE;
}