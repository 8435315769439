@import '../../../app.scss';

#transactions-search-section {
  width: 75%;
}

.search-box {
  width: 20%;
}

#search-input {
  border-left-color: white;
}

#search-input :focus {
  border-color: none;
}

#search-placeholder {
  background-color: white;
  border-right-color: white;
}

.transactions-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center !important;
}

.transaction-column {
  width: calc(100% / 12) !important;
}
