@import '../../../app.scss';

$left-width: 1.8vw;

.content {
  margin-left: 3vw;
  padding: 0 0 1%;
}

.sidebar {
  transition: ease-out 0.2s ease;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 3vw;
  z-index: 1000;
  overflow: hidden;
  background-color: $zarph-color;
  color: $zarph-white;
  box-shadow: 0.5px 0px 5px rgba(0, 73, 96, 0.3);
  border-right: 1px solid rgba(0, 47, 61, 0.5);
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  padding-top: 1%;
}

.sidebar.sidebar-on {
  width: 15vw;
}

.sidebar-top-section {
  width: 100%;
  height: 11%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1vh;
  border-bottom: 1px solid rgba(255, 253, 253, 0.4);
}

.sidebar-top-section.collapsed {
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-top-logo-sml {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo-sml {
  width: 2vw;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar-top-left {
  width: $left-width;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-btn {
  display: flex;
  justify-content: center;
  background: none;
  border: none;
}

#sidebar-open-btn-img {
  width: 100%;
  height: 100%;
}

.sidebar-top-right {
  width: calc(100% - #{$left-width});
  padding-left: 5px;
}

.brandLogo {
  width: 10vw;
  animation: fadeIn 0.5s;
}

.sidebar-content-section {
  margin-top: 5%;
  height: 87%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-tabs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-tab {
  padding: 0 !important;
  margin: 0.3vh 0;
  width: 100%;
  height: 4.2vh;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border-radius: 2px;
}

.nav-tab.collapsed {
  width: 87%;
  justify-content: center;
}

.nav-tab:hover {
  background-color: $zarph-dark-color;
}

.nav-tab.active {
  //background-color: $zarph-dark-color !important;
  background-color: rgba(255, 253, 253, 0.3);
}

.nav-tab-left {
  width: $left-width;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-tab-left.active {
  //background-color: rgba(255, 253, 253, 0.3);
  border-radius: 2px;
}

.sidebar-icon {
  width: 70%;
}

.nav-tab-right {
  width: calc(100% - #{$left-width});
  padding-left: 4%;
  display: flex;
  align-items: center;
  color: #e7ebee;
  text-decoration: none;
  justify-content: space-between;
}

.sidebar-label {
  animation: fadeIn 0.2s;
  font-size: 1rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar-content-bottom {
  height: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-content-bottom-profile-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

#profile-tab {
  margin-bottom: 1vh;
}

.sidebar-bottom {
  height: 87%;
  width: 100%;
  border-top: 1px solid rgba(255, 253, 253, 0.4);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sidebar-logout-btn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  color: $zarph-white !important;
}

.sidebar-logout-btn :hover {
  color: $zarph-white;
}

.sidebar-bottom-left {
  width: $left-width;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.version {
  font-size: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 575px) {
  .content {
    margin-left: 0;
  }

  .sidebar.sidebar-off {
    width: 0;
  }

  .sidebar.sidebar-on {
    width: 80vw;
  }

  .sidebar-top-left {
    width: 0;
  }

  .sidebar-btn {
    display: none;
  }

  .sidebar-top-right {
    width: 100%;
    padding: 0;
  }

  .brandLogo {
    width: 40vw;
  }

  .sidebar-content-section {
    height: 82%;
  }

  .nav-tab {
    height: 6vh;
  }

  .nav-tab-left {
    width: 10vw;
  }

  .sidebar-label {
    font-size: 1.2rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .content {
    margin-left: 0;
  }

  .sidebar.sidebar-off {
    width: 0;
  }

  .sidebar.sidebar-on {
    width: 80vw;
  }

  .sidebar-top-left {
    width: 0;
  }

  .sidebar-btn {
    display: none;
  }

  .sidebar-top-right {
    width: 100%;
    padding: 0;
  }

  .brandLogo {
    width: 30vw;
  }

  .sidebar-content-section {
    height: 82%;
  }

  .nav-tab {
    height: 6vh;
  }

  .nav-tab-left {
    width: 8vw;
  }

  .sidebar-label {
    font-size: 1.3rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content {
    margin-left: 0;
  }

  .sidebar.sidebar-off {
    width: 0;
  }

  .sidebar.sidebar-on {
    width: 40vw;
  }

  .sidebar-top-left {
    width: 0;
  }

  .sidebar-btn {
    display: none;
  }

  .sidebar-top-right {
    width: 100%;
    padding: 0;
  }

  .brandLogo {
    width: 20vw;
  }

  .sidebar-content-section {
    height: 82%;
  }

  .nav-tab {
    height: 6vh;
  }

  .nav-tab-left {
    width: 5vw;
  }

  .sidebar-label {
    font-size: 1.1rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .content {
    margin-left: 5vw;
  }

  .sidebar.sidebar-off {
    width: 5vw;
  }

  .sidebar.sidebar-on {
    width: 20vw;
  }

  .sidebar-top-section {
    height: 10%;
  }

  .sidebar-top-left {
    width: 3vw;
  }

  .sidebar-top-right {
    width: calc(100% - 3vw);
  }

  .nav-tab-left {
    width: 3vw;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .content {
    margin-left: 4vw;
  }

  .sidebar.sidebar-off {
    width: 4vw;
  }

  .sidebar-top-section {
    height: 10%;
  }

  .sidebar-top-left {
    width: 2.5vw;
  }

  .sidebar-top-right {
    width: calc(100% - 2.5vw);
  }

  .nav-tab-left {
    width: 2.5vw;
  }
}

@media only screen and (min-width: 2560px) {
  .content {
    margin-left: 5vw;
  }

  .sidebar {
    width: 5vw;
  }
}

/* .navBrand {
  width: 100%;
  height: 7vh;
  padding-left: 10%;
  border-bottom: 1px solid rgba(255, 253, 253, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navTabLink {
  width: 100%;
  height: 5vh;

  display: flex;
  flex-direction: row;

  align-items: center;

  margin: 2% 0;

  padding: 0 5% 0 10%;
}

.navTabLink span {
  font-size: 1vw;
  color: $zarph-white;
}

.sidebar-icon {
  width: 1.7vw;
  margin-right: 10%;
}

.content {
  margin-left: 15vw;
  overflow-x: scroll;
}

#sidebarLogo {
  width: 2.3vw;
}

.sidebar.sidebar-on #sidebarLogo {
  width: 6vw;
}

.active {
  background-color: rgba(255, 253, 253, 0.1);
  border-left: 5px solid $zarph-light-color;
}

.navLogout {
  width: 100%;
  height: 7vh;
  padding-left: 10%;
  border-top: 1px solid rgba(255, 253, 253, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navLogout span {
  color: $zarph-white;
  font-size: 1vw;
}

@media (max-width: 2559px) {
  .sidebar.sidebar-off > .w-100 > .navBrand {
    padding-left: 27%;
  }

  .sidebar.sidebar-off > .w-100 > .w-100.mt-5 > .navTabLink {
    padding: 0 5% 0 16%;
  }

  .sidebar.sidebar-off > .w-100 > .w-100.mt-5 > .navTabLink > .sidebar-icon {
    margin-right: 22%;
  }

  .sidebar.sidebar-off > .w-100.d-flex.flex-column.justify-content-between > .navTabLink {
    padding: 0 5% 0 16%;
  }

  .sidebar.sidebar-off > .w-100.d-flex.flex-column.justify-content-between > .navLogout {
    padding: 0 5% 0 16%;
  }

  #sidebarLogo {
    width: 1.4vw;
  }

  .sidebar-icon {
    width: 2vw;
  }

  .sidebar.sidebar-on #sidebarLogo {
    width: 7vw;
  }

  .content {
    margin-left: 3vw;
  }

  .active {
    background-color: rgba(255, 253, 253, 0.1);
    border-left: 4px solid $zarph-light-color;
  }
}

@media (max-width: 1439px) {
  .sidebar.sidebar-off {
    width: 5vw;
  }

  .sidebar.sidebar-on {
    width: 20vw;
  }

  #sidebarLogo {
    width: 2.5vw;
  }

  .sidebar-icon {
    width: 3vw;
  }

  .sidebar.sidebar-on #sidebarLogo {
    width: 10vw;
  }

  .sidebar.sidebar-off > .w-100 > .w-100.mt-5 > .navTabLink,
  .sidebar.sidebar-off > .w-100.d-flex.flex-column.justify-content-between > .navTabLink,
  .sidebar.sidebar-off > .w-100.d-flex.flex-column.justify-content-between > .navLogout {
    padding: 0 5% 0 19%;
  }

  .sidebar.sidebar-off > .w-100 > .w-100.mt-5 > .navTabLink > .sidebar-icon {
    margin-right: 25%;
  }

  .sidebar.sidebar-on
    > .w-100.d-flex.flex-column.justify-content-between
    > .navLogout
    > .d-flex.align-items-center
    > .sidebar-icon {
    margin-right: 18%;
  }

  .navTabLink span,
  .navLogout span {
    font-size: 2vw;
  }

  .navLogout > .sidebar-icon {
    margin-right: 15%;
  }

  .content {
    margin-left: 5vw;
  }
}

//Tablet Sizes

@media (max-width: 1023px) {
  .navBrand {
    height: 8vh;
    padding-left: 5%;
  }

  .sidebar.sidebar-off {
    width: 6.4vw;
  }

  .sidebar.sidebar-on {
    width: 50vw;
  }

  #sidebarLogo {
    width: 3.5vw;
  }

  .sidebar-icon {
    width: 4vw;
    margin-right: 5%;
  }

  .navTabLink {
    height: 6vh;
    padding: 0 5% 0 5%;
  }

  .navLogout {
    padding-left: 5%;
  }

  .sidebar.sidebar-on #sidebarLogo {
    width: 15vw !important;
  }

  .sidebar.sidebar-off > .w-100 > .w-100.mt-5 > .navTabLink,
  .sidebar.sidebar-off > .w-100.d-flex.flex-column.justify-content-between > .navTabLink,
  .sidebar.sidebar-off > .w-100.d-flex.flex-column.justify-content-between > .navLogout {
    padding: 0 5% 0 22%;
  }

  .sidebar.sidebar-off > .w-100 > .w-100.mt-5 > .navTabLink > .sidebar-icon {
    margin-right: 25%;
  }

  .sidebar.sidebar-on
    > .w-100.d-flex.flex-column.justify-content-between
    > .navLogout
    > .d-flex.align-items-center
    > .sidebar-icon {
    margin-right: 13%;
  }

  .navTabLink span,
  .navLogout span {
    font-size: 3vw;
  }

  .navLogout > .sidebar-icon {
    margin-right: 15%;
  }

  .content {
    margin-left: 7vw;
  }

  .sidebar.sidebar-on #sidebarLogo {
    width: 7vw;
  }
}

@media (max-width: 767px) {
  #sidebarLogo {
    width: 2.5vw;
  }

  .sidebar.sidebar-on #sidebarLogo {
    width: 9vw;
  }
}

@media (max-width: 576px) {
  .sidebar {
    .link-text {
      display: block !important;
    }

    width: 75vw;

    font-size: 5vw;
  }

  .sidebar-on {
    padding: 2%;
  }

  .sidebar.sidebar-on #sidebarLogo {
    width: 25vw;
  }

  .sidebar-off {
    max-width: 0px;
  }

  .content {
    height: 100vh;
    margin-left: 0px;
  }
} */
