@import '../../../app.scss';

.equipment-table {
  font-size: 95%;
}

.onlineStatus {
  color: green;
}

.offlineStatus {
  color: red;
}

.equipmentSearchRow {
  align-items: center;
}

.equipmentSearchLabel {
  margin-bottom: 0 !important;
}

.equipmentPDF {
  width: 10px !important;
}

.pdfBtn {
  color: red;
}

.offline-row:hover {
  cursor: not-allowed;
}

.show-equipment-table > thead > tr > th {
  width: 20%;
}

.status-list-table > thead > .show-equipment-table-break {
  background-color: $zarph-color;
  height: 3vh;
}

.status-list-table > thead > tr > .status-list-cell {
  width: 50%;
}
