@import '../../../app.scss';

#user-container {
  padding: 2%;
  height: 85vh;
}

.user-section-top-half {
  height: 50%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #e7ebee;
}

.first-section {
  width: calc(100% / 3);
  display: flex;
  flex-direction: row;
}

.first-section-left {
  width: 50%;
}

.first-section-right {
  width: 50%;
}

.second-section {
  width: calc(100% / 3);
  border-right: 2px solid #e7ebee;
  border-left: 2px solid #e7ebee;
}

.third-section {
  width: calc(100% / 3);
}

.permissionsRow {
  columns: 2;
}
