@import '../../../app.scss';

.categories-content-section {
  height: 89%;
  padding: 1%;
  background-color: #f8f9fa;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 4px;
}

.top-right-categories {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#categoryId {
  width: 3%;
}

#categoryDelete {
  width: 3%;
}

#categoryUpdate {
  width: 3%;
}
