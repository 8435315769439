@import "../../../app.scss";

#table-video {
    height: 100%;
    object-fit: scale-down;
}

.media-container {
    height: 15em;
    width: 50%;
    padding: 0 6%;
}

.ratio {
    height: 100%;
    width: 75%;
}