@import '~bootstrap/scss/bootstrap';

$zarph-color: #004960;
$zarph-secondary: #016273;
$zarph-light-color: #47d4ff;
$zarph-green: #03abac;
$zarph-dark-color: #003f52;
$zarph-white: #fcfcfc;

#root {
  width: 100vw;
  height: 98vh;
}

body {
  color: $zarph-color;
  height: 100vh !important;
  width: 100vw;
  background-color: $zarph-white;
  font-size: 18px;
  overflow: hidden;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 1440px) {
  #root {
    height: 97vh;
  }
  body {
    font-size: 18px !important;
  }
}

@media (max-width: 1024px) {
  body {
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 16px !important;
  }
}

p {
  color: $zarph-color;
}

label {
  color: $zarph-color;
}

.nav-tabs {
  color: $zarph-color;
}

.activeRow {
  background-color: $zarph-white;
}

//Index shared styles

.index-container {
  height: 100vh;
  margin: 1% 0;
  background-color: transparent;
  padding: 1%;
}

@media screen and (max-width: 1600px) {
  .index-container {
    height: 95%;
  }
}

.collapsed-container {
  padding: 1% 2% 1% 12% !important;
}

.top-index-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1%;
}

.top-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-right {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/* .index-table-title {
  width: 100%;
  height: 8vh;
  padding: 1% 1.5%;
  background-color: #004960;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #f8f9fa;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
} */

/* .index-table-title h2 {
  margin-left: 3%;
  margin-top: 1%;
} */

/* .title-left {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #e7ebee;
} */

.table-title-img {
  max-width: 6%;
}

.title-right {
  width: 50%;
  display: inline-flex;
  justify-content: flex-end;
}

.btn-table {
  display: flex;
  text-align: center;
  margin-right: 1%;
  height: fit-content;
  width: fit-content;
  margin-top: 0.5%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.add-btn {
  border: 1px solid $zarph-color;
  background-color: $zarph-color;
  color: #f8f9fa !important;
}

.add-btn:hover {
  background-color: $zarph-dark-color !important;
}

.read-btn {
  color: $zarph-color !important;
  background-color: transparent !important;
  border: 2px solid;
  border-color: $zarph-color !important;
}

.updateBtn {
  background-color: $zarph-color;
  color: white;
  outline: $zarph-color;
  border-color: $zarph-color;
}

.updateBtn :hover {
  background-color: #005e7a;
  outline: #005e7a;
  border-color: #005e7a;
}

.content-section {
  height: 93%;
  padding: 1%;
  background-color: #f8f9fa;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 4px;
}

.top-content-section {
  height: 7%;
  margin-bottom: 1%;
  padding-bottom: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 73, 96, 0.2);
}

.search-section {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.search-form {
  width: 100%;
}

.results-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 14%;
}

.results-select {
  margin: 0 3%;
}

.index-table {
  background-color: transparent;
  color: #004960;
  height: 90%;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.index-table thead th {
  position: sticky;
  z-index: 1;
}

.data-table {
  color: $zarph-color;
}

.table-head {
  background-color: transparent !important;
  color: rgba(0, 73, 96, 0.4) !important;
  border: none !important;
}

.table-head-row {
  border: none !important;
}

.index-table::-webkit-scrollbar {
  display: none;
}

.media-container {
  height: 15em;
  width: 50%;
}

.image-container {
  height: 15em;
  width: 100%;
}

.table-image {
  height: inherit;
  width: 55%;
  object-fit: scale-down;
}

.delete-btn {
  background-color: transparent;
  border: none;
  color: red;
}

.update-btn {
  background-color: transparent;
  border: none;
  color: $zarph-color;
}

// Modal shared styles

.modal-heading {
  border-bottom: 1px solid #e7ebee;
  margin-bottom: 1%;
  width: 100%;
}

.event-modal-heading {
  border-bottom: 1px solid #e7ebee;
  margin-bottom: 1%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 1%;
}

.event-modal-heading h5 {
  margin-left: 1%;
}

.event-modal-icon {
  width: 3%;
}

.file-row {
  height: 15em;
}

.media-wrapper {
  position: relative;
  width: 100%;
  height: inherit;
  aspect-ratio: 4/3;
}

// Forms

.form-control:focus {
  box-shadow: none;
  border-color: $zarph-color;
}

.previewContainer {
  align-items: center;
  justify-content: center;
  margin: 3%;
}

.previewImage {
  max-width: 35%;
}

.selectPlaceholder {
  color: #6a758a;
}

//App

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
